<template>
  <div class="c-header-with-order">
    <span class="c-header-with-order__label">{{label}}</span>
    <img  class="c-header-with-order__icon" src="@/assets/searchResults/search_icon_sort.svg" @click="orderSerach" />
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HeaderWithOrder',
  props: {
    label: {
      type: String,
      default: '',
    },
  },
  setup() {
    const orderSerach = () => {
      // TODO
      console.log('orderSerach');
    };

    return {
      orderSerach,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-header-with-order {
  @include flex();

  &__icon {
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>

import { h } from 'vue';
import { v4 as uuid } from 'uuid';
// import PlaylistsNameFunction from '@/components/Global/MusicPlayer/Fragment/PlaylistsNameFunction.vue';
import SearchAlbumInfo from '@/components/Global/MusicPlayer/Fragment/SearchAlbumInfo.vue';
import Track from '@/components/Global/MusicPlayer/Fragment/Track.vue';
// import Remove from '@/components/Global/MusicPlayer/Fragment/Remove.vue';
import Id from '@/components/Global/MusicPlayer/Fragment/Id.vue';
import MusicFunction from '@/components/Global/MusicPlayer/MusicFunction.vue';
import OrderName from '@/components/Global/MusicPlayer/Fragment/OrderName.vue';
// import PlaylistsNameExpandFunction from '@/components/Global/MusicPlayer/Fragment/PlaylistsNameExpandFunction.vue';
import TrackDivideFunction from '@/components/Global/MusicPlayer/Fragment/TrackDivideFunction.vue';
import VersionAndEditNameFunction from '@/components/Global/MusicPlayer/Fragment/VersionAndEditNameFunction.vue';
import CountLabel from '@/components/Global/MusicPlayer/Fragment/CountLabel.vue';
import CoverNameCode from '@/components/Global/MusicPlayer/Fragment/CoverNameCode.vue';
import TimeLength from '@/components/Global/MusicPlayer/Fragment/TimeLength.vue';
import More from '@/components/Global/MusicPlayer/Fragment/More.vue';
import MoreSearchAlbums from '@/components/Global/MusicPlayer/Fragment/MoreSearchAlbums.vue';
import OrderNameIdSearchAlbums from '@/components/Global/MusicPlayer/Fragment/OrderNameIdSearchAlbums.vue';
import HeaderWithOrder from '@/components/Global/MusicPlayer/HeaderFragment/HeaderWithOrder.vue';

export const functionConfig = {
  search: {
    searchType: 'playlists', // 搜尋類別
  },
  info: {
    list: [
      {
        label: '總歌單',
        data: 12,
      },
      // {
      //   label: '來自其他用戶',
      //   data: 4,
      // },
    ],
  },
  listFilter: {
    checkboxs: [
      {
        label: 'Waves',
        value: 'waves',
      },
      {
        label: 'Description',
        value: 'description',
      },
    ],
  },
  functionIcons: {
    list: [
      {
        icon: 'move',
      },
      {
        icon: 'copy',
      },
      {
        icon: 'delete',
      },
      {
        icon: 'invite',
      },
      {
        icon: 'download',
      },
    ],
  },
  addPlaylistsBtn: {
    visible: true,
  },
  downloadBtn: {
    visible: true,
  },
};

export const mobileListConfig = {
  columns: [
    {
      type: 'label',
      label: '歌單名稱',
      align: 'left',
      key: 'playlistsName',
      render: CoverNameCode,
      id: uuid(),
    },
    {
      type: 'label',
      label: '',
      align: 'center',
      width: '60px',
      key: 'track',
      render: h(CountLabel, {
        label: 'Tracks',
      }),
      id: uuid(),
    },
    {
      type: 'label',
      label: '',
      align: 'center',
      width: '24px',
      key: 'more',
      render: h(MoreSearchAlbums, {
        functionList: ['add-to-favorite', 'add-to-playlists', 'share', 'album-info', 'to-album-page'],
      }),
      id: uuid(),
    },
  ],
};

// export const pcListConfig = () => ({
export const pcListConfig = {
  columns: [
    {
      id: uuid(),
      type: 'label',
      label: '歌單名稱',
      align: 'left',
      // width: exactDeviceType === 'sm-pc' ? '350px' : '500px',
      // width: '500px',
      key: 'playlistsName',
      renderHeader: h(HeaderWithOrder, {
        label: '歌單名稱',
      }),
      render: SearchAlbumInfo,
    },
    {
      type: 'label',
      label: '',
      // width: '300px',
      align: 'left',
      key: 'owner',
      render: h(MusicFunction, {
        showDetailExpand: true,
        // ml: true,
      }),
      id: uuid(),
    },

    {
      type: 'label',
      label: '',
      width: '60px',
      align: 'left',
      key: 'owner',
      id: uuid(),
      // render: Avatar,
    },
    {
      type: 'label',
      label: '曲目',
      width: '60px',
      align: 'left',
      key: 'track',
      render: h(Track, {
        expand: false,
      }),
      id: uuid(),
    },
    {
      type: 'label',
      label: 'ID',
      width: '100px',
      align: 'left',
      key: 'id',
      render: Id,
      id: uuid(),
    },
    {
      type: 'label',
      label: '',
      width: '100px',
      align: 'left',
      key: 'empty',
      id: uuid(),
    },
    {
      type: 'checkbox',
      label: 'C',
      width: '60px',
      align: 'center',
      key: 'checkbox',
      id: uuid(),
    },
    // {
    //   type: 'label',
    //   label: '',
    //   align: 'center',
    //   width: '60px',
    //   key: 'more',
    //   render: More,
    // },
  ],
};
// });

export const contentData = [
  {
    playlistsName: '我的最愛',
    name: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    id: uuid(),
  },
  {
    playlistsName: '哈哈',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    id: uuid(),
  },
  {
    playlistsName: '哈哈2',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    id: uuid(),
  },
  {
    playlistsName: '哈哈3',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    id: uuid(),
  },
  {
    playlistsName: '哈哈4',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    id: uuid(),
  },
  {
    playlistsName: '哈哈5',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    id: uuid(),
  },
];

export const mobileListDetailColumns = [
  {
    type: 'label',
    label: '123',
    align: 'center',
    // width: '24px',
    key: 'cover-name-code',
    render: OrderNameIdSearchAlbums,
    id: uuid(),
  },
  {
    type: 'label',
    label: '長度',
    width: '60px',
    align: 'center',
    render: TimeLength,
    id: uuid(),
  },
  {
    type: 'label',
    label: '',
    align: 'center',
    width: '24px',
    key: 'more',
    render: More,
    id: uuid(),
  },
];

export const pcListDetailColumns = [
  {
    type: 'label',
    label: '曲目名稱',
    // width: '500px',
    align: 'left',
    key: 'playlistsName',
    render: OrderName,
    id: uuid(),
  },
  {
    type: 'label',
    label: '',
    // width: '300px',
    align: 'left',
    key: 'owner',
    render: h(MusicFunction, {
      showInnerMusicExpand: true,
    }),
    id: uuid(),
  },
  {
    type: 'label',
    label: '版本',
    width: '60px',
    align: 'left',
    key: 'version',
    id: uuid(),
  },
  {
    type: 'label',
    label: '長度',
    width: '60px',
    align: 'left',
    key: 'length',
    id: uuid(),
  },
  {
    type: 'label',
    label: 'ID',
    width: '100px',
    align: 'left',
    key: 'id',
    id: uuid(),
  },
  {
    type: 'label',
    label: '分軌',
    width: '100px',
    align: 'left',
    key: 'trackNo',
    id: uuid(),
  },
  // {
  //   type: 'label',
  //   label: 'REMOVE',
  //   width: '100px',
  //   align: 'center',
  //   key: 'remove',
  //   render: Remove,
  // },
  {
    type: 'checkbox',
    label: 'C',
    width: '60px',
    align: 'center',
    key: 'checkbox',
    id: uuid(),
  },
  // {
  //   type: 'label',
  //   label: '',
  //   align: 'center',
  //   width: '60px',
  //   key: 'more',
  //   render: More,
  // },
];

export const listDetailContentData = [
  {
    playlistsName: '我的最愛',
    name: 'hello world',
    track: 10,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '12:32',
    version: 5,
    trackNo: 5,
    id: 'MAT305-5',
  },
  {
    playlistsName: '哈哈',
    name: 'simliiref fds',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:19',
    trackNo: 5,
    version: 3,

    id: 'MAT305-76',
  },
];

export const musicDetailColumns = [
  {
    type: 'label',
    label: '曲目名稱',
    // width: '500px',
    align: 'left',
    key: 'playlistsName',
    render: VersionAndEditNameFunction,
    id: uuid(),
  },
  {
    type: 'label',
    label: '',
    // width: '300px',
    align: 'left',
    key: 'owner',
    render: h(MusicFunction, {
      showExpand: true,
      ml: true,
    }),
    id: uuid(),
  },
  {
    type: 'label',
    label: '',
    width: '60px',
    align: 'left',
    key: 'emtpy1',
    id: uuid(),
  },
  {
    type: 'label',
    label: '版本',
    width: '60px',
    align: 'left',
    key: 'versdion',
    id: uuid(),
  },
  {
    type: 'label',
    label: '長度',
    width: '60px',
    align: 'left',
    key: 'length',
    id: uuid(),
  },
  {
    type: 'label',
    label: 'ID',
    width: '100px',
    align: 'left',
    key: 'id',
    id: uuid(),
  },
  // {
  //   type: 'label',
  //   label: '分軌',
  //   width: '100px',
  //   align: 'left',
  //   key: 'trackNo',
  // },
  {
    type: 'label',
    label: '',
    width: '100px',
    align: 'center',
    key: 'fds',
    id: uuid(),
  },
  {
    type: 'checkbox',
    label: 'C',
    width: '60px',
    align: 'center',
    key: 'checkbox',
    id: uuid(),
  },
  // {
  //   type: 'action',
  //   label: '',
  //   width: '60px',
  //   align: 'left',
  //   key: 'action',
  // },
];

export const musicDetailContentData = [
  {
    name: 'Full length',
    extraInfo: 'Vocal Lead',
    playlistsName: '我的最愛',
    owner: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    length: '12:32',
    version: 5,
    id: 'MAT305-5',
  },
  {
    name: 'Full length',
    extraInfo: 'Vocal Lead',
    playlistsName: '哈哈',
    track: 3,
    modifyDate: '27 Aug 2021',
    length: '03:19',
    version: 3,
    id: 'MAT305-76',
  },
];

export const trackDivideColumns = [
  {
    type: 'label',
    label: '曲目名稱',
    width: '500px',
    align: 'left',
    key: 'playlistsName',
    render: TrackDivideFunction,
    id: uuid(),
  },
  {
    type: 'label',
    label: '',

    align: 'left',
    key: 'owner',
    render: h(MusicFunction, {
      showExpand: false,
      ml: true,
    }),
    id: uuid(),
  },
  {
    type: 'label',
    label: '長度',
    width: '60px',
    align: 'left',
    key: 'length',
    id: uuid(),
  },
  {
    type: 'label',
    label: 'ID',
    width: '100px',
    align: 'left',
    key: 'id',
    id: uuid(),
  },
  {
    type: 'label',
    label: '',
    width: '100px',
    align: 'center',
    key: 'fds',
    id: uuid(),
  },
  {
    type: 'checkbox',
    label: 'C',
    width: '60px',
    align: 'center',
    key: 'checkbox',
    id: uuid(),
  },
  // {
  //   type: 'label',
  //   label: 'C',
  //   width: '60px',
  //   align: 'center',
  //   key: 'empty',
  // },
];

export const trackDivideData = [
  {
    name: 'Clarinet&Basso',
    extraInfo: 'Vocal Lead',
    playlistsName: '我的最愛',
    owner: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    length: '12:32',
    version: 5,
    id: 'MAT305-5',
  },
  {
    name: 'Low Pizzicato',
    extraInfo: 'Vocal Lead',
    playlistsName: '哈哈',
    track: 3,
    modifyDate: '27 Aug 2021',
    length: '03:19',
    version: 3,
    id: 'MAT305-76',
  },
];

<template>
  <div class="c-cover-info">
    <img class="c-cover-info__cover" src="@/assets/explore/playlists_img.jpg" />
    <div class="c-cover-info__detail">
      <p class="c-cover-info__detail__name">Bags of Fun</p>
      <div
        class="c-cover-info__detail__desc"
        :class="{
          'c-cover-info__detail__desc--active': expandStatus,
        }"
      >
        <p v-show="showInfo">
          Indie pop ballad with delayed, airy, overdriven lead guitar flying above a lively bass and simple drum groove.
          Hints of melancholy drift in and out as the progression develops.
        </p>
      </div>
    </div>
    <!-- <div class="expand" :class="{ 'expand--active': expandStatus }">
      <img class="expand-img" src="@/assets/icon/icon_expand.svg" @click="handleExpand('detail')" />
    </div> -->
  </div>
</template>
<script>
import { defineComponent, inject, computed } from 'vue';

export default defineComponent({
  name: 'SearchAlbumInfo',
  props: {
    showInfo: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const expandStatus = computed(() => inject('expandStatus')().detail);
    const handleExpand = inject('handleExpand');

    return {
      expandStatus,
      handleExpand,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-cover-info {
  @include flex();
  @include padding(24px 20px 24px 0);
  max-width: 500px;

  &__cover {
    width: 90px;
    height: 90px;
    border-radius: 5px;
  }

  &__detail {
    margin-left: 16px;

    &__name {
      @include font-style($c-black, 18, 700, 0.8px, 26px);
    }

    &__desc {
      @include font-style($c-assist14, 16, 400, 0.8px, 22px);
      @include word-ellipsis(3);

      &--active {
        @include word-ellipsis-unlimited();
      }
    }
  }
}

.expand {
  width: 10px;
  margin-left: 20px;
  vertical-align: bottom;
  cursor: pointer;
  transition: 0.3s;
  transform: rotate(0deg);

  &--active {
    transform: rotate(-180deg);
  }
}
</style>

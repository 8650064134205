<template>
  <div class="c-layer-third">
    <ul class="third-layer-list">
      <li class="third-layer-list__item" v-for="item of data" :key="item.key">
        <div class="option">
          <div class="option__info">
            <p class="option__info__title">{{ item.label }} {{ item.labelEn }}</p>
            <!-- <p class="option__info__total">({{ item.total }})</p> -->
          </div>

          <div class="option__operation">
            <div class="option__operation__icon">
              <div class="option__operation__icon__add"></div>
              <div class="option__operation__icon__remove"></div>
            </div>

          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LayerThird',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
});
</script>
<style lang="scss" scoped>
.c-layer-third {
  width: 100%;
  height: 100%;
  border-top: 1px solid $c-assist2;
}

.third-layer-list {
  @include padding(15px 12px);

  &__item {
    @include flex(flex-start, flex-start);
    background-color: $c-white;
    border-radius: 5px;
    // height: 43px;

    & + & {
      margin-top: 16px;
    }
  }
}

.option {
  @include flex(flex-start, flex-start);
  width: 100%;
  height: 100%;
  position: relative;

  &__info {
    @include flex(space-between, flex-start, column);
    height: 100%;
    flex: 1;

    &__title {
      @include font-style($c-assist6, 16, normal, 0.8px, 20px);
        white-space: normal;

    }

    &__total {
      @include font-style($c-assist3, 16, normal, 0.8px, 20px);
    }
  }

  &__operation {
    margin-left: 33px;
    height: 100%;

    &__icon {
      @include flex();

      &__add {
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url('~@/assets/searchResults/icon_filter_with.svg');
        cursor: pointer;
      }

      &__remove {
        margin-left: 10px;
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url('~@/assets/searchResults/icon_filter_without.svg');
        cursor: pointer;
      }
    }
  }

}
</style>

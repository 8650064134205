<template>
  <div class="v-search-history">
    <base-layout>
      <my-music-function-bar :config="functionConfig" />
      <my-music-list :config="listConfig" :contentData="contentData"/>
    </base-layout>
  </div>
</template>
<script>
import { defineComponent, provide, ref } from 'vue';

import BaseLayout from '@/components/MyMusic/BaseLayout.vue';
import MyMusicFunctionBar from '@/components/MyMusic/MyMusicFunctionBar.vue';
import MyMusicList from '@/components/MyMusic/MyMusicList/index.vue';
import { functionConfig, contentData, listConfig } from '@/config/myMusic/searchHistoryConfig';

export default defineComponent({
  name: 'MyMusicSearchHistory',
  components: {
    BaseLayout,
    MyMusicList,
    MyMusicFunctionBar,
  },
  setup() {
    const listContentConfig = ref({
      notice: '※ 僅保留近一週之紀錄',
      expandDetail: '', // list-detail, music-detail, ''
    });

    provide('listContentConfig', listContentConfig);

    return {
      functionConfig,
      listConfig,
      contentData,
    };
  },
});
</script>

<template>
  <div class="c-layer-second">
    <div class="c-layer-second__content">
      <div class="title-info">
        <p class="title-info__curr-title">主題</p>
        <p class="title-info__clear-all">清除全部</p>
      </div>
      <div class="second-layer-list-wrap">
        <ul class="second-layer-list">
          <layer-second-item v-for="item of data" :key="item.key" :data="item"/>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
// import LayerThird from '@/components/SearchResult/LayerThird.vue';
import LayerSecondItem from '@/components/SearchResult/LayerSecondItem.vue';
import { thirdLayer } from '@/config/search/sidebarSearchConfig';

export default defineComponent({
  name: 'LayerSecond',
  components: {

    LayerSecondItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      thirdLayer,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-layer-second {
  @include padding(20px);
  width: 280px;
  height: 100%;
  background-color: $c-assist10;

  &__content {
    width: 100%;
    height: 100%;
  }
}

.title-info {
  @include flex(space-between);
  margin-bottom: 16px;

  &__curr-title {
    @include font-style($c-black, 16, normal, 1.6px, 26px);
  }

  &__clear-all {
    @include font-style($c-assist4, 12, normal, 1.2px, 24px);
  }
}

.second-layer-list-wrap {
  height: 100%;
  overflow: auto;
}

.second-layer-list {
  &__item {
    // @include flex(flex-start, flex-start);
    background-color: $c-white;
    border-radius: 5px;

    &__box {
      // height: 82px;
      height: 100%;
      @include padding(10px);
    }

    & + & {
      margin-top: 12px;
    }
  }
}

.option {
  @include flex(flex-start, flex-start);
  width: 100%;
  height: 100%;
  position: relative;

  &__info {
    flex: 1;

    &__title {
      @include font-style($c-assist6, 16, 600, 0.8px, 20px);
      white-space: normal;
    }

    &__total {
      @include font-style($c-assist3, 16, normal, 0.8px, 20px);
    }
  }

  &__operation {
    margin-left: 33px;

    &__icon {
      @include flex();

      &__add {
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url('~@/assets/searchResults/icon_filter_with.svg');
        cursor: pointer;
      }

      &__remove {
        margin-left: 10px;
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url('~@/assets/searchResults/icon_filter_without.svg');
        cursor: pointer;
      }
    }
  }

  &__more-icon {
    @include position(br, 0, 0);
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    background-image: url('~@/assets/searchResults/icon_info_open.svg');
    transition: 0.3s;
    transform: rotate(0deg);

    &--active {
      transform: rotate(180deg);
    }
  }
}
</style>

<template>
  <div class="c-more">
    <img class="c-more__img" src="@/assets/myMusic/icon_more.svg" @click.stop="handleShowFunction" />

    <!-- <template v-if="!isSmPc">
      <mobile-function v-model:showFunction="showFunction" />
    </template> -->
  </div>
</template>
<script>
import {
  defineComponent, ref, reactive, computed,
} from 'vue';
// import { NCollapseTransition, NModal } from 'naive-ui';
import { useRouter } from 'vue-router';
// import ModelBaseLayout from '@/components/Global/Popup/ModelBaseLayout.vue';
// import Add from '@/components/Global/Popup/Add.vue';
// import Note from '@/components/Global/Popup/Note.vue';
// import MoveToPlaylists from '@/components/Global/Popup/MoveToPlaylists.vue';
// import MobileFunction from '@/components/Global/MusicPlayer/MobileFunction.vue';
import { useSiteStore } from '@/store/site';
// import MobileFunction from '@/components/Global/MusicPlayer/MobileFunction.vue';

export default defineComponent({
  name: 'MusicMore',
  props: {
    functionList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    // NCollapseTransition,
    // NModal,
    // ModelBaseLayout,
    // Add,
    // MobileFunction,
    // Note,
    // MoveToPlaylists,
  },

  setup() {
    const router = useRouter();
    const siteStore = useSiteStore();
    const expandStatus = ref(false);
    const showFunction = ref(false);

    const list = [
      {
        key: 'to-album-page',
        type: 'route',
        route: {
          name: 'explore-albums-info',
        },
        label: '前往專輯頁面',
      },
      {
        key: 'note',
        type: 'modal',
        modalKey: 'note',
        label: '查看曲目筆記',
      },
      {
        key: 'add-to-queue',
        type: 'function',
        label: '新增至播放序列',
      },
      {
        key: 'edit-playlist-name',
        type: 'modal',
        modalKey: 'add',
        label: '新增至歌單',
      },
      {
        key: 'edit-playlist-name',
        type: 'modal',
        modalKey: 'move-to-playlists',
        label: '搬移至歌單',
      },
    ];

    const isSmPc = computed(() => {
      if (siteStore.deviceType.includes('sm-pc')) {
        return true;
      }

      return false;
    });

    const modelStatus = reactive({
      note: false,
      add: false,
      'move-to-playlists': false,
    });

    const activityShareRecordTab = ref('share');

    const handleClick = (data) => {
      console.log('data', data.type);
      if (data.type === 'route') {
        router.push(data.route);
      }

      if (data.type === 'function') {
        // TODO
        console.log('do some api...');
      }

      if (data.type === 'modal') {
        console.log('data.modelKey', data.modelKey);
        modelStatus[data.modalKey] = true;
      }
    };

    const toggleAlbumFunction = () => {
      showFunction.value = !showFunction.value;
    };

    const handleShowFunction = () => {
      // toggleAlbumFunction();

      siteStore.toggleShowMobileFunction(true);

      // expandStatus.value = !expandStatus.value;
    };

    return {
      modelStatus,
      handleClick,
      list,
      expandStatus,
      handleShowFunction,
      activityShareRecordTab,
      showFunction,
      toggleAlbumFunction,
      isSmPc,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-more {
  position: relative;

  &__img {
    cursor: pointer;
    width: 16px;
  }
}
.more-list-wrap {
  @include position(tr, 100%, 0%);
  @include flex(center);
  @include padding(20px 0);
  width: 160px;
  background-color: $c-white;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #00000029;
  z-index: 10;
}

.more-list {
  &__item {
    @include flex(space-between);
    @include font-style($c-assist4, 16, 400, 0.8px, 24px);
    cursor: pointer;

    & + & {
      margin-top: 12px;
    }
  }
}

@media screen and (min-width: $pc) {
  .more-list-wrap {
    @include position(tl, 100%, 0);
  }
}
</style>

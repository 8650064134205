<template>
  <div class="c-filter-conditions">
    <div class="c-filter-conditions__content">
      <div class="close-wrap" :class="{ 'close-wrap--active': isfilterExpand }" @click="toggleFilter">
        <!-- <p class="close-wrap__text">篩選條件</p> -->
        <img class="close-wrap__icon" src="@/assets/searchResults/icon_filter_red.svg" />
      </div>
      <div class="open-wrap" :class="{ 'open-wrap--active': isfilterExpand }">
        <div class="open-wrap__header">
          <div class="open-wrap__header__box">
            <div class="header-box__left">
              <img class="header-box__left__icon" src="@/assets/searchResults/icon_filter.svg" />
              <p class="header-box__left__text">篩選條件</p>
            </div>
            <div class="header-box__right">
              <p class="header-box__right__text">清除全部</p>
              <img
                class="header-box__right__icon"
                src="@/assets/searchResults/icon_close_filter.svg"
                @click="toggleFilter"
              />
            </div>
          </div>
        </div>
        <div class="function-wrap">
          <div class="music-length">
            <p class="function-name">長度</p>
            <progress-bar />
            <div class="function-label">
              <p class="function-label__text">00:00-00:50</p>
              <div class="function-label__text">03:00+</div>
            </div>
          </div>
          <div class="music-bpm">
            <p class="function-name">BPM</p>
            <progress-bar />
            <div class="function-label">
              <p class="function-label__text">40</p>
              <div class="function-label__text">200+</div>
            </div>
          </div>
          <div class="music-only-track">
            <n-checkbox :value="onlyShowTrackMusic" label="只顯示包含分軌的曲目" />
          </div>

          <div class="music-filter-list">
            <p class="music-filter-list__remaining">
              <img class="music-filter-list__remaining__icon" src="@/assets/myMusic/icon_info.svg" />
              可選擇標籤 3/10
            </p>
            <layer-first
              v-for="data of firstLayer"
              :key="data.key"
              :data="data"
              v-model:expandKey="expandKey"
              @handleExpandKey="handleExpandKey"
            />
          </div>
        </div>
      </div>

      <!-- second layer -->
      <div class="second-layer-wrap" :class="{ 'second-layer-wrap--active': isExpandSecondLayer }">
        <layer-second :data="secondLayer" />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from 'vue';
import { NCheckbox } from 'naive-ui';
import ProgressBar from '@/components/Global/ProgressBar.vue';
import LayerFirst from '@/components/SearchResult/LayerFirst.vue';
import LayerSecond from '@/components/SearchResult/LayerSecond.vue';
import { firstLayer, secondLayer } from '@/config/search/sidebarSearchConfig';

export default defineComponent({
  name: 'FilterConditions',
  props: {
    isfilterExpand: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ProgressBar,
    NCheckbox,
    LayerFirst,
    LayerSecond,
  },
  emits: ['update:isfilterExpand'],
  setup(props, { emit }) {
    // const isfilterExpand = ref(true);
    const isExpandSecondLayer = ref(false);
    const onlyShowTrackMusic = ref('t');
    const expandKey = ref('');

    const toggleFilter = () => {
      isExpandSecondLayer.value = false;
      emit('update:isfilterExpand', !props.isfilterExpand);
    };

    const toggleSecondLayer = (status) => {
      isExpandSecondLayer.value = status;
    };

    const handleExpandKey = (key) => {
      if (expandKey.value === key) {
        expandKey.value = '';
        toggleSecondLayer(false);

        return;
      }

      expandKey.value = key;
    };

    watch(expandKey, (newVal) => {
      if (newVal) {
        toggleSecondLayer(true);
      }
    });

    return {
      toggleFilter,
      toggleSecondLayer,
      isExpandSecondLayer,
      onlyShowTrackMusic,
      firstLayer,
      expandKey,
      secondLayer,
      handleExpandKey,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-filter-conditions {
  &__content {
    display: none;
  }
}

@media screen and (min-width: $sm-pc) {
  .c-filter-conditions {
    &__content {
      height: 100%;
      position: relative;
      display: block;
    }
  }

  .close-wrap {
    @include flex();
    cursor: pointer;
    @include padding(18px);
    position: fixed;
    top: 80px;
    left: 0;
    transform: translateX(0px);
    transition: 0.3s;
    width: 200px;

    &--active {
      transform: translateX(-280px);
    }

    &__text {
      @include font-style($c-assist4, 18, bold, 3.6px, 26px);
    }

    &__icon {
      width: 24px;
      // margin-left: 10px;
    }
  }

  .open-wrap {
    display: block;
    width: 280px;
    height: calc(100% - 80px);
    transition: 0.3s;
    transform: translateX(-280px);
    position: fixed;
    top: 80px;
    left: 0;
    box-shadow: 0px 0px 3px rgba($c-black, 0.05);
    @include flex(flex-start, flex-start, column);
    z-index: 10;

    &--active {
      transform: translateX(0);
    }

    &__header {
      width: 100%;
      height: 80px;
      @include bg-gradient-90deg();
      @include flex(space-between, center);
      @include padding(20px);

      &__box {
        width: 100%;
        @include flex(space-between);
      }
    }
  }

  .header-box {
    &__left {
      @include flex();

      &__icon {
        width: 24px;
      }

      &__text {
        margin-left: 12px;
        @include font-style($c-white, 18, bold, 3.6px, 26px);
      }
    }

    &__right {
      @include flex();

      &__text {
        @include font-style(rgba($c-white, 0.6), 12, normal, 1.2px, 24px);
      }

      &__icon {
        width: 24px;
        margin-left: 16px;
        cursor: pointer;
      }
    }
  }

  .function-wrap {
    flex: 1;
    @include padding(25px 20px);
    // height: 100%;
    width: 100%;
    overflow: auto;
    background-color: $c-white;
  }

  .function-name {
    @include font-style($c-black, 16, normal, 1.6px, 26px);
  }

  .function-label {
    @include flex(space-between);

    &__text {
      @include font-style($c-assist4, 14, normal, 0.7px, 26px);
    }
  }

  .music-bpm {
    margin-top: 20px;
  }

  .music-only-track {
    margin-top: 20px;
  }

  .second-layer-wrap {
    position: fixed;
    top: 80px;
    left: 280px;
    height: 100%;
    transform: translateX(-560px);
    transition: 0.3s;

    &--active {
      transform: translateX(0px);
    }
  }
}

.music-filter-list {
  text-align: right;
  &__remaining {
    @include flex(flex-end);

    &__icon {
      margin-right: 5px;
    }

    margin-top: 28px;
    margin-bottom: 8px;

  }
}
</style>

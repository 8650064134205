<template>
  <div class="c-type-and-name">
    <p class="text">
      <span class="text__type">{{ data.type }}</span>
      <span class="text__name">{{ data.name }}</span>
    </p>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TypeAndName',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
});
</script>
<style lang="scss" scoped>
.text {
  &__type {
    @include font-style($c-assist4, 16, 600, 0.8px, 22px);

  }

  &__name {
    margin-left: 5px;
    @include font-style($c-black, 16, 600, 0.8px, 22px);

  }
}

</style>

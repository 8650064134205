<template>
  <div class="c-order-name-id">
    <p class="order">{{ idx + 1 }}.</p>
    <div class="info">
      <p class="info__name">{{ data.name }}</p>
      <p class="info__id">{{ data.id }}</p>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OrderAndName',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    idx: {
      type: Number,
      default: 0,
    },
  },
});
</script>
<style lang="scss" scoped>

.c-order-name-id {
  @include flex();
  margin-left: 5px;

}
.order {
  @include font-style($c-assist3, 16, bold, 0.8px, 26px);
}

.info {
  margin-left: 32px;
  text-align: left;

  &__name {
    @include font-style($c-assist6, 14, bold, 0px, 24px);
  }

  &__id {
    @include font-style($c-assist4, 14, 400, 0px, 24px);
  }
}
</style>

<template>
  <div class="c-playlists-name-function">
    <div class="c-playlists-name-function__name">
      <span class="c-playlists-name-function__name__text">{{ data.playlistsName }}</span>

      <img class="c-playlists-name-function__name__img" v-show="idx === 0" src="@/assets/myMusic/icon_favourite.svg" />
    </div>

  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PlaylistsNameFunction',

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    column: {
      type: Object,
      default: () => ({}),
    },
    idx: {
      type: Number,
    },
  },
});
</script>
<style lang="scss" scoped>
.c-playlists-name-function {
  width: 100%;
  @include flex();

  &__name {
    @include flex();
    flex: none;
    // width: 300px;

    &__text {
      @include font-style($c-black, 16, medium, 0.8px, 22px);
    }

    &__img {
      margin-left: 10px;
    }
  }

}
</style>

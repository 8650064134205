<template>
  <div class="c-track">
    <p class="count">10</p>
    <div class="expand" :class="{'expand--active': expandStatus}" v-if="expand">
      <img class="expand-img" src="@/assets/icon/icon_expand.svg" @click="handleExpand('detail')"/>
    </div>
  </div>
</template>
<script>
import { defineComponent, inject, computed } from 'vue';

export default defineComponent({
  name: 'Track',
  props: {
    expand: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const expandStatus = computed(() => inject('expandStatus')().detail);
    const handleExpand = inject('handleExpand');

    return {
      expandStatus,
      handleExpand,
    };
  },
});
</script>
<style lang="scss" scoped>

.c-track {
  @include flex();

}
.count {
  @include font-style($c-black, 14, normal, 0, 26px);
}

.expand {
  width: 10px;
  margin-left: 20px;
  vertical-align: bottom;
  cursor: pointer;
  transition: .3s;
  transform: rotate(0deg);

  &--active {
    transform: rotate(-180deg);
  }
}
</style>

<template>
  <div class="c-search-labels">
    <ul class="label-list">
      <li class="label-list__item" :class="`label-list__item--${item.type}`" v-for="item of allLabels" :key="item.key">
        <div class="label-list__item__box">
          <p class="label-list__item__box__text">{{ item.label }}</p>
          <img class="label-list__item__box__remove-icon" src="@/assets/searchResults/icon_tag_delete.svg" @click="removeLabel(item.key)"/>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import { useSearchStore } from '@/store/search';

export default defineComponent({
  name: 'SearchLabels',
  emits: ['removeLabel'],
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    activeTab: {
      type: String,
      default: '',
    },
    labels: {
      types: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const searchStore = useSearchStore();
    const removeLabel = (key) => {
      emit('removeLabel', key);
    };

    console.log('searchStore.includeLabels', searchStore);

    const allLabels = computed(() => searchStore.allLabels);

    return {
      removeLabel,
      allLabels,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-search-labels {
  display: inline-block;
  @include flex();
  margin-top: 22px;
}

.label-list {
  @include flex();
  flex-wrap: wrap;

  &__item {
    height: 30px;
    @include padding(0px 10px);
    @include flex();

      margin-right: 12px;
      margin-bottom: 12px;

    &--include {
      background-color: $c-main;
    }

    &--exclude {
      background-color: $c-assist4;
    }

    &__box {
      @include flex();

      &__text {
        @include font-style($c-white, 14, bold, 0.7px, 18px);
      }

      &__remove-icon {
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }
}
</style>

<template>
  <p class="c-id">
    EN027-1
  </p>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Id',
});
</script>
<style lang="scss" scoped>
.c-id {
  @include font-style($c-assist6, 14, 400, 0.7px, 26px);

}

</style>

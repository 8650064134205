<template>
  <div
    class="c-first-layer"
    :class="{ 'c-first-layer--active': data.key === expandKey }"
    @click="handleExpand(data.key)"
  >
    <div class="c-first-layer__content">
      <div class="c-first-layer__content__left">
        <p class="layer-label">{{ data.label }}</p>
        <p class="layer-count" v-show="data.selectedCount">
          <span class="layer-count__text">{{ data.selectedCount }}</span>
        </p>
      </div>
      <div class="c-first-layer__content__right">
        <div class="expand" :class="{ 'expand--active': data.key === expandKey }">
          <img class="expand__icon" src="@/assets/searchResults/icon_filter_more.svg" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FirstLayer',
  emits: ['handleExpandKey'],
  props: {
    data: {
      type: Object,
      default: () => ({
        label: '測試',
        selectedCount: '3',
        key: '',
      }),
    },
    expandKey: {
      type: String,
      default: 'version',
    },
  },
  setup(props, { emit }) {
    const handleExpand = (key) => {
      emit('handleExpandKey', key);
    };

    return {
      handleExpand,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-first-layer {
  background-color: $c-white3;
  @include padding(9px 12px);
  border-radius: 5px;
  @include flex(center);
  // margin-top: 28px;
  cursor: pointer;
    transition: .3s;

  &--active {
    background-color: $c-assist10;
  }

  & + & {
    margin-top: 12px;
  }

  &__content {
    width: 100%;
    @include flex(space-between);

    &__left {
      @include flex();
    }

    &__right {
    }
  }
}

.layer-label {
  @include font-style($c-black, 16, normal, 1.6px, 26px);
}

.layer-count {
  @include inline-flex(center);
  @include padding(5px);
  height: 18px;
  border-radius: 9px;
  background-color: $c-main;
  position: relative;
  margin-left: 10px;

  &__text {
    @include font-style($c-white, 12, bold, 0.6px 26px);
    // @include position(center);
  }
}

.expand {
  width: 10px;
  height: 10px;
  @include flex(center);
  transition: 0.3s;

  &--active {
    transform: rotate(180deg);
  }

  &__icon {
    vertical-align: bottom;
  }
}
</style>

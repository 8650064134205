<template>
  <div class="c-progress-bar">
    <div class="progress-wrap">
      <div class="progress-static" :style="{ height: `${height}px` }"></div>
      <div class="progress-line" :style="{ width: `${30}%`, height: `${height}px` }">
        <div class="progress-line__dot"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProgressBar',
  props: {
    height: {
      type: [String, Number],
      default: '1',
    },
  },
});
</script>
<style lang="scss" scoped>
.c-progress-bar {
  width: 100%;
}
.progress-wrap {
  @include flex(center);
  height: 24px;
  cursor: pointer;
  position: relative;
}

.progress-static {
  background-color: $c-text1;
  height: 1px;
  width: 100%;
  background-color: $c-assist7;
  @include position(center);
}

.progress-line {
  background-color: $c-main;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  &__dot {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    @include circle(1px);
    background-color: $c-main;
    transition: 0.3s;
  }
}

.progress-wrap {
  &:hover {
    .progress-line__dot {
      right: -3px;
      @include circle(6px);
    }
  }
}
</style>

<template>
  <div class="c-count-label">
    <p class="count">{{data[column.key]}}</p>
    <p class="tracks">{{label}}</p>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TrackCount',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    column: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      default: '',
    },

  },
  setup() {
    return {};
  },
});
</script>
<style lang="scss" scoped>
.c-count-label {
  width: 30px;
  @include inline-flex(center, center, column);
}
.count {
  @include font-style($c-assist4, 14, 400, 0.7px, 26px);
}

.tracks {
  display: inline-block;

  @include font-style($c-assist4, 20, 400, 0.7px, 10px);
  transform: scale(0.5);
}
</style>

<template>
  <div class="mi-pagination" :class="{'mi-pagination--is-white-bg': isWhiteBg}">
    <div class="mi-pager-list" v-if="pager.totalPage !== 1">
      <!-- v-show="pager.pageIndex !== 1 || pager.totalPage === 1" -->
      <div class="mi-pager-list__item" @click="handleJumpPage('prev')">
        <button class="mi-pager-list__item__btn">
          <div class="mi-pager-list__item__btn__icon mi-pager-list__item__btn__icon--prev"></div>
          <span class="mi-pager-list__item__btn__text">上一頁</span>
        </button>
      </div>

      <div class="mi-pager-list__item mi-pager-list__item--elevator">
        <p class="mi-pager-list__item__text">前往頁面</p>
        <input class="mi-pager-list__item__input" v-maska="'###'" />
        <p class="mi-pager-list__item__text">of 192</p>
      </div>

      <!-- v-show="pager.pageIndex !== pager.totalPage && pager.totalPage > 1" -->
      <div class="mi-pager-list__item" @click="handleJumpPage('next')">
        <button class="mi-pager-list__item__btn">
          <span class="mi-pager-list__item__btn__text">下一頁</span>
          <div class="mi-pager-list__item__btn__icon mi-pager-list__item__btn__icon--next"></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'MiSearchPagination',
  props: {
    size: {
      type: String,
      default: 'default',
      validator: (val) => ['small', 'default'].includes(val),
    },
    pager: {
      type: Object,
      default: () => ({}),
    },
    activeColor: {
      type: String,
      default: 'black',
    },
    isWhiteBg: {
      type: Boolean,
      defualt: false,
    },
  },
  emits: ['handlePagination'],
  setup(props, { emit }) {
    const firstPage = ref(1);
    const route = useRoute();

    const renderPage = computed(() => {
      // 如果小於6 直接返回
      if (props.pager.totalPage <= 6) {
        const pageList = [];
        for (let i = 1; i <= props.pager.totalPage; i += 1) {
          pageList.push(i);
        }
        return pageList;
      }

      let pageList = [
        props.pager.pageIndex - 2,
        props.pager.pageIndex - 1,
        props.pager.pageIndex,
        props.pager.pageIndex + 1,
        props.pager.pageIndex + 2,
      ];

      pageList = pageList.filter((ele) => ele >= firstPage.value && ele <= props.pager.totalPage);

      // 補1
      if (props.pager.pageIndex - 2 - firstPage.value === 1) {
        pageList.splice(0, 0, 1);
      }

      // 補... 和 1
      if (props.pager.pageIndex - 2 - firstPage.value > 1) {
        pageList.splice(0, 0, '⋯');
        pageList.splice(0, 0, 1);
      }

      // 補最大頁數
      if (props.pager.totalPage - (props.pager.pageIndex + 2) >= 1) {
        pageList.push(props.pager.totalPage);
      }

      // 補最大頁數...
      if (props.pager.totalPage - (props.pager.pageIndex + 2) > 1) {
        pageList.splice(-1, 0, '⋯');
      }

      return pageList;
    });

    const handleJumpPage = (operate, page) => {
      // 唯一的string是刪節號，直接返回
      if (typeof page === 'string') {
        return;
      }

      let targetPage = 0;

      if (operate === 'prev') {
        targetPage = 1;
      }

      if (operate === 'prev') {
        targetPage = props.pager.pageIndex - 1;
      }

      if (operate === 'next') {
        targetPage = props.pager.pageIndex + 1;
      }

      if (operate === 'last') {
        targetPage = props.pager.totalPage;
      }

      if (operate === 'jump') {
        targetPage = page;
      }

      emit('handlePagination', targetPage);
    };

    const isActive = (pageNumber, isWhite) => {
      const condition = Number(props.pager.pageIndex) === Number(pageNumber) || Number(route.query.pageIndex) === Number(pageNumber);

      if (isWhite) {
        return condition && props.activeColor === 'white';
      }

      return condition;
    };

    return {
      firstPage,
      renderPage,
      handleJumpPage,
      route,
      isActive,
    };
  },
});
</script>

<style lang="scss" scoped>
.mi-pagination {
  text-align: center;

  &--is-white-bg {
    margin-top: 50px;
   .mi-pager-list__item__input {
     border: 1px solid $c-assist7
   }

   .mi-pager-list__item__text {
     @include font-style($c-assist3, 14, 400, 0.7px, 26px);
   }
  }
}

.mi-pager-list {
  display: inline-flex;

  &__item {
    @include flex(center);
    height: 36px;
    position: relative;

    &--elevator {
      @include flex();
    }

    &__btn {
      width: 60px;
      height: 40px;
      display: inline-block;
      background-color: $c-assist7;
      outline: transparent;
      border: transparent 0px;
      border-radius: 20px;
      @include flex(center);
      cursor: pointer;
      transition: 0.4s;

      &:hover {
        background-color: $c-main;

        .mi-pager-list__item__btn__icon {
          &--prev {
            background-image: url('~@/assets/icon/page_prev_white.svg');
          }

          &--next {
            background-image: url('~@/assets/icon/page_next_white.svg');
          }
        }
      }

      &__text {
        display: none;
      }

      &__icon {
        width: 20px;
        height: 20px;
        background-size: 8px;
        background-position: center;
        background-repeat: no-repeat;

        &--prev {
          background-image: url('~@/assets/icon/page_prev.svg');
        }

        &--next {
          background-image: url('~@/assets/icon/page_next.svg');
        }
      }

    }

    &__input {
      width: 60px;
      height: 40px;
      display: inline-block;
      @include padding(0);
      outline: transparent;
      border: transparent 0px;
      border-radius: 4px;
      // TOFIX 等串了 API 後，專輯可以放在背景時，把這改回白色
      background-color: $c-white;
      text-align: center;
      @include font-style($c-black, 16, 700, 0.7px, 26px);
    }

    &__text {
      @include font-style($c-white, 14, 400, 0.7px, 26px);
      @include padding(0 16px);
    }
  }
}

.page-number {
  @include font-style($c-assist4, 16, 600, 0.8px, 26px);
  position: relative;

  &:hover {
    &::after {
      content: '';
      width: 10px;
      height: 3px;
      background-color: $c-main;
      display: inline-block;
      @include position(bl, -8px, 50%);
      transform: translateX(-50%);
    }
  }

  &--active {
    transition: 0.3s;
    color: $c-black;

    &--white {
      color: $c-white;
    }

    &::after {
      content: '';
      width: 10px;
      height: 3px;
      background-color: $c-main;
      display: inline-block;
      @include position(bl, -8px, 50%);
      transform: translateX(-50%);
    }
  }
}

@media screen and (min-width: $sm-pc) {
  .mi-pager-list {
    &__item {
      &__btn {
        width: 100px;
        @include padding(0 15px);
        @include flex(space-between);

        &:hover {
        background-color: $c-main;

        .mi-pager-list__item__btn__text {
          color: $c-white;
        }
      }

        &__text {
          display: inline-block;
          @include font-style($c-assist3, 14, 400, 0.7px, 26px);
        }

        &__icon {

          width: 10px;
        }
      }
    }
  }
}
</style>

import { h } from 'vue';
// import CoverName from '@/components/Global/MusicPlayer/Fragment/CoverName.vue';
import { v4 as uuid } from 'uuid';
import Remove from '@/components/Global/MusicPlayer/Fragment/Remove.vue';
// import ModifyDate from '@/components/Global/MusicPlayer/Fragment/ModifyDate.vue';
import MusicFunction from '@/components/Global/MusicPlayer/MusicFunction.vue';
import PlaylistsNameExpandFunction from '@/components/Global/MusicPlayer/Fragment/PlaylistsNameExpandFunction.vue';
import TrackDivideFunction from '@/components/Global/MusicPlayer/Fragment/TrackDivideFunction.vue';
import VersionAndEditNameFunction from '@/components/Global/MusicPlayer/Fragment/VersionAndEditNameFunction.vue';
// import PlayHistoryMore from '@/components/Global/MusicPlayer/Fragment/PlayHistoryMore.vue';
import More from '@/components/Global/MusicPlayer/Fragment/More.vue';
import PlaylistsNameFunction from '@/components/Global/MusicPlayer/Fragment/PlaylistsNameFunction.vue';
import CountLabel from '@/components/Global/MusicPlayer/Fragment/CountLabel.vue';
import CoverNameCode from '@/components/Global/MusicPlayer/Fragment/CoverNameCode.vue';
import MoreSearchTracks from '@/components/Global/MusicPlayer/Fragment/MoreSearchTracks.vue';
import TimeLength from '@/components/Global/MusicPlayer/Fragment/TimeLength.vue';
import SearchAlbumInfo from '@/components/Global/MusicPlayer/Fragment/SearchAlbumInfo.vue';
import HeaderWithOrder from '@/components/Global/MusicPlayer/HeaderFragment/HeaderWithOrder.vue';

export const functionConfig = {
  search: {
    searchType: 'play-history', // 搜尋類別
  },
  info: {
    list: [

    ],
  },
  listFilter: {
    checkboxs: [
      {
        label: 'Waves',
        value: 'waves',
      },
      {
        label: 'Description',
        value: 'description',
      },
      {
        label: 'Tags',
        value: 'tags',
      },

    ],

  },
  functionIcons: {
    list: [

      {
        icon: 'delete',
      },
      {
        icon: 'download',
      },

    ],
  },
  addPlaylistsBtn: {
    visible: true,
  },
  downloadBtn: {
    visible: true,
  },
};

export const mobileListConfig = {
  columns: [
    {
      type: 'label',
      label: '歌單名稱',
      align: 'left',
      key: 'playlistsName',
      render: CoverNameCode,
      id: uuid(),
    },
    {
      type: 'label',
      label: '',
      align: 'center',
      width: '60px',
      key: 'track',
      render: TimeLength,
      id: uuid(),
      // render: h(CountLabel, {
      //   label: 'Tracks',
      // }),
    },
    {
      type: 'label',
      label: '',
      align: 'center',
      width: '24px',
      key: 'more',
      render: h(MoreSearchTracks, {}),
      id: uuid(),
    },
  ],
};

// export const pcListConfig = () => ({
export const pcListConfig = {
  columns: [
    {
      id: uuid(),
      type: 'label',
      label: '曲目名稱',
      // width: exactDeviceType === 'sm-pc' ? '350px' : '500px',
      // width: '500px',
      align: 'left',
      key: 'coverName',
      renderHeader: h(HeaderWithOrder, {
        label: '曲目名稱',
      }),
      render: h(SearchAlbumInfo, {
        showInfo: false,
      }),

    },
    {
      id: uuid(),
      type: 'label',
      label: '',
      // width: '300px',
      align: 'left',
      key: 'owner',
      render: h(MusicFunction, {
        showDetailExpand: true,
      }),
    },

    {
      // id: uuid(),
      type: 'label',
      label: '版本',
      width: '50px',
      align: 'left',
      key: 'version',
    },

    {
      // id: uuid(),
      type: 'label',
      label: '長度',
      width: '100px',
      align: 'left',
      key: 'length',
    },
    {
      // id: uuid(),
      type: 'label',
      label: 'ID',
      width: '100px',
      align: 'left',
      key: 'id',
    },
    {
      // id: uuid(),
      type: 'label',
      label: '分軌',
      width: '50px',
      align: 'left',
      key: 'trackNo',
    },

    // {
    // id: uuid(),
    //   type: 'label',
    //   label: '播放日期',
    //   width: '100px',
    //   align: 'left',
    //   key: 'modifyDate',
    //   render: ModifyDate,
    // },
    // {
    //   type: 'label',
    //   label: 'REMOVE',
    //   width: '100px',
    //   align: 'center',
    //   key: 'remove',
    //   render: Remove,
    // },

    {
      id: uuid(),
      type: 'checkbox',
      label: 'C',
      width: '60px',
      align: 'center',
      key: 'checkbox',
    },
    // {
    //   type: 'label',
    //   label: '',
    //   width: '60px',
    //   align: 'center',
    //   key: 'action',
    //   render: PlayHistoryMore,

    // },

  ],
};
// });

export const contentData = [
  {
    coverName: 'Bags of Fun',
    owner: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:19',
    trackNo: 5,
    version: 3,
    id: 'MAT305-76',
  },
  {
    coverName: 'Silver',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:19',
    trackNo: 5,
    version: 2,
    id: 'MAT305-76',
  },
  {
    coverName: 'Silver',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:19',
    trackNo: 5,
    version: 2,
    id: 'MAT305-76',
  },
  {
    coverName: 'Silver',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:19',
    trackNo: 5,
    version: 2,
    id: 'MAT305-76',
  },
  {
    coverName: 'Silver',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:19',
    trackNo: 5,
    version: 2,
    id: 'MAT305-76',
  },
];

export const mobileListDetailColumns = [
  {
    id: uuid(),
    type: 'label',
    label: '歌單名稱',
    align: 'left',
    key: 'playlistsName',
    render: PlaylistsNameFunction,
  },
  {
    id: uuid(),
    type: 'label',
    label: '',
    align: 'center',
    width: '60px',
    key: 'track',
    render: h(CountLabel, {
      label: 'Notes',
    }),
  },
  {
    id: uuid(),
    type: 'label',
    label: '',
    align: 'center',
    width: '24px',
    key: 'more',
    render: More,
  },
];

export const pcListDetailColumns = [
  {
    id: uuid(),
    type: 'label',
    label: '曲目名稱',
    align: 'left',
    key: 'playlistsName',
    render: PlaylistsNameExpandFunction,

  },
  {
    id: uuid(),
    type: 'label',
    label: '版本',
    width: '100px',
    align: 'left',
    key: 'version',
  },
  {
    id: uuid(),
    type: 'label',
    label: '長度',
    width: '100px',
    align: 'left',
    key: 'length',
  },
  {
    id: uuid(),
    type: 'label',
    label: 'ID',
    width: '100px',
    align: 'left',
    key: 'id',
  },
  {
    id: uuid(),
    type: 'label',
    label: '分軌',
    width: '100px',
    align: 'left',
    key: 'trackNo',
  },
  {
    id: uuid(),
    type: 'label',
    label: 'REMOVE',
    width: '100px',
    align: 'center',
    key: 'remove',
    render: Remove,
  },
  {
    id: uuid(),
    type: 'checkbox',
    label: 'C',
    width: '60px',
    align: 'center',
    key: 'checkbox',
  },
  {
    id: uuid(),
    type: 'action',
    label: '',
    width: '60px',
    align: 'left',
    key: 'action',
  },
];

export const listDetailContentData = [
  {
    playlistsName: '我的最愛',
    owner: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '12:32',
    version: 5,
    trackNo: 5,
    id: 'MAT305-5',

  },
  {
    playlistsName: '哈哈',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:19',
    trackNo: 5,
    version: 3,
    id: 'MAT305-76',
  },
];

export const musicDetailColumns = [
  {
    id: uuid(),
    type: 'label',
    label: '曲目名稱',
    width: '500px',
    align: 'left',
    key: 'playlistsName',
    render: VersionAndEditNameFunction,
  },
  {
    id: uuid(),
    type: 'label',
    label: '',
    // width: '300px',
    align: 'left',
    key: 'owner',
    render: h(MusicFunction, {
      showExpand: false,
      ml: true,
    }),
  },
  {
    id: uuid(),
    type: 'label',
    label: '',
    width: '60px',
    align: 'left',
    key: 'ver',
  },
  {
    id: uuid(),
    type: 'label',
    label: '長度',
    width: '50px',
    align: 'left',
    key: 'versio',
  },
  {
    id: uuid(),
    type: 'label',
    label: '長度',
    width: '100px',
    align: 'left',
    key: 'length',
  },
  {
    id: uuid(),
    type: 'label',
    label: 'ID',
    width: '100px',
    align: 'left',
    key: 'id',
  },

  {
    id: uuid(),
    type: 'label',
    label: '分軌',
    width: '50px',
    align: 'left',
    key: 'trackNo',
  },
  // {
  //   type: 'label',
  //   label: 'ID',
  //   width: '100px',
  //   align: 'left',
  //   key: 'idfds',
  // },
  // {
  //   type: 'label',
  //   label: 'REMOVE',
  //   width: '100px',
  //   align: 'center',
  //   key: 'remove',
  // },
  {
    id: uuid(),
    type: 'checkbox',
    label: 'C',
    width: '60px',
    align: 'center',
    key: 'checkbox',
  },
  // {
  //   type: 'action',
  //   label: '',
  //   width: '60px',
  //   align: 'left',
  //   key: 'action',
  // },
];

export const musicDetailContentData = [
  {
    name: 'Full length',
    extraInfo: 'Vocal Lead',
    playlistsName: '我的最愛',
    owner: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    length: '12:32',
    version: 5,
    id: 'MAT305-5',

  },
  {
    name: 'Full length',
    extraInfo: 'Vocal Lead',
    playlistsName: '哈哈',
    track: 3,
    modifyDate: '27 Aug 2021',
    length: '03:19',
    version: 3,
    id: 'MAT305-76',
  },
];

export const trackDivideColumns = [
  {
    id: uuid(),
    type: 'label',
    label: '曲目名稱',
    width: '500px',
    align: 'left',
    key: 'playlistsName',
    render: TrackDivideFunction,
  },
  {
    id: uuid(),
    type: 'label',
    label: '',
    // width: '300px',
    align: 'left',
    key: 'owner',
    render: h(MusicFunction, {
      showExpand: false,
      ml: true,
    }),
  },
  {
    id: uuid(),
    type: 'label',
    label: '',
    width: '50px',
    align: 'left',
    key: 'dsf',
  },
  {
    id: uuid(),
    type: 'label',
    label: '長度',
    width: '100px',
    align: 'left',
    key: 'length',
  },
  {
    id: uuid(),
    type: 'label',
    label: 'ID',
    width: '100px',
    align: 'left',
    key: 'id',
  },
  {
    id: uuid(),
    type: 'label',
    label: '分軌',
    width: '50px',
    align: 'left',
    key: 'trackNo',
  },
  // {
  //   type: 'label',
  //   label: '分軌',
  //   width: '100px',
  //   align: 'left',
  //   key: 'trackfdsNo',
  // },
  // {
  //   type: 'label',
  //   label: 'REMOVE',
  //   width: '100px',
  //   align: 'center',
  //   key: 'remove',
  // },
  {
    id: uuid(),
    type: 'checkbox',
    label: 'C',
    width: '60px',
    align: 'center',
    key: 'checkbox',
  },
  // {
  //   type: 'label',
  //   label: 'C',
  //   width: '60px',
  //   align: 'center',
  //   key: 'empty',

  // },

];

export const trackDivideData = [
  {
    name: 'Clarinet&Basso',
    extraInfo: 'Vocal Lead',
    playlistsName: '我的最愛',
    owner: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    length: '12:32',
    version: 5,
    id: 'MAT305-5',

  },
  {
    name: 'Low Pizzicato',
    extraInfo: 'Vocal Lead',
    playlistsName: '哈哈',
    track: 3,
    modifyDate: '27 Aug 2021',
    length: '03:19',
    version: 3,
    id: 'MAT305-76',
  },
];

<template>
  <div
    class="c-cover-name-code"
    :class="{
      'c-cover-name-code--ml-offset': mlOffset,
    }"
  >
    <img class="c-cover-name-code__cover" src="@/assets/explore/playlists_img.jpg" />
    <p class="c-cover-name-code__info">
      <span class="c-cover-name-code__info__name">Bags of Fun fjdslkfjlkdsjfldsjflkds</span>
      <span class="c-cover-name-code__info__code">EN027-1</span>
    </p>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CoverNameCode',
  props: {
    mlOffset: {
      type: Boolean,
      default: true,
    },
  },
});
</script>
<style lang="scss" scoped>
.c-cover-name-code {
  @include flex();

  &--ml-offset {
    margin-left: -16px;
  }

  &__cover {
    width: 52px;
    height: 52px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &__info {
    margin-left: 16px;
    @include flex(flex-start, flex-start, column);

    &__name {
      text-align: left;
      @include font-style($c-assist6, 14, bold, 0.8px, 18px);
      @include word-ellipsis(1);
    }

    &__code {
      text-align: left;
      @include font-style($c-assist4, 14, 400, 0.8px, 18px);
      @include word-ellipsis(1);
    }
  }
}

</style>

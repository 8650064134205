<template>
  <li class="second-layer-list__item">
    <div class="second-layer-list__item__box">
      <div class="option">
        <div class="option__info">
          <p class="option__info__title">{{ data.label }} {{ data.labelEn }}</p>
          <!-- <p class="option__info__total">({{ data.total }})</p> -->
        </div>

        <div class="option__operation">
          <div class="option__operation__icon">
            <div
              class="option__operation__icon__add"
              :class="{
                'option__operation__icon__add--active': activeGroupKeys.includes(data.id),
              }"
              @click="addLabelGroup(data)"
            ></div>
            <div class="option__operation__icon__remove" @click="removeLabelGroup(data)"></div>
          </div>
        </div>
      </div>

      <div class="more">
        <div class="more__icon" :class="{ 'more__icon--active': isExpandThirdLayer }" @click="expandThirdLayer"></div>
      </div>
    </div>

    <n-collapse-transition :show="isExpandThirdLayer">
      <layer-third :data="data.detail" />
    </n-collapse-transition>
  </li>
</template>
<script>
import { defineComponent, ref, computed } from 'vue';
import { NCollapseTransition } from 'naive-ui';
import LayerThird from '@/components/SearchResult/LayerThird.vue';
import { thirdLayer } from '@/config/search/sidebarSearchConfig';
import { useSearchStore } from '@/store/search';

export default defineComponent({
  name: 'LayerSecondItem',
  components: {
    LayerThird,
    NCollapseTransition,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const searchStore = useSearchStore();
    const isExpandThirdLayer = ref(false);
    const expandThirdLayer = () => {
      isExpandThirdLayer.value = !isExpandThirdLayer.value;
    };

    const addLabelGroup = (data) => {
      const { id, detail } = data;
      searchStore.setIncludeLabelGroup(id, detail);
    };

    const removeLabelGroup = (data) => {
      const { id, detail } = data;
      console.log('!!!', id, detail);
      searchStore.setExcludeLabelGroup(id, detail);
    };

    const activeGroupKeys = computed(() => Object.keys(searchStore.includeLabelGroup));

    return {
      thirdLayer,
      isExpandThirdLayer,
      expandThirdLayer,
      addLabelGroup,
      removeLabelGroup,
      activeGroupKeys,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-layer-second {
  @include padding(20px);
  width: 280px;
  height: 100%;
  background-color: $c-assist10;

  &__content {
    width: 100%;
    height: 100%;
  }
}

.title-info {
  @include flex(space-between);
  margin-bottom: 16px;

  &__curr-title {
    @include font-style($c-black, 16, normal, 1.6px, 26px);
  }

  &__clear-all {
    @include font-style($c-assist4, 12, normal, 1.2px, 24px);
  }
}

.second-layer-list-wrap {
  height: 100%;
  overflow: auto;
}

.second-layer-list {
  &__item {
    // @include flex(flex-start, flex-start);
    background-color: $c-white;
    border-radius: 5px;

    &__box {
      // height: 82px;
      height: 100%;
      @include padding(10px);
    }

    & + & {
      margin-top: 12px;
    }
  }
}

.option {
  @include flex(flex-start, flex-start);
  width: 100%;
  height: 100%;
  position: relative;

  &__info {
    flex: 1;

    &__title {
      @include font-style($c-assist6, 16, 600, 0.8px, 20px);
      white-space: normal;
    }

    &__total {
      @include font-style($c-assist3, 16, normal, 0.8px, 20px);
    }
  }

  &__operation {
    margin-left: 33px;

    &__icon {
      @include flex();

      &__add {
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url('~@/assets/searchResults/icon_filter_with.svg');
        cursor: pointer;

        &:hover {
          background-image: url('~@/assets/searchResults/icon_filter_with_hover.svg');
        }

        &--active {
          background-image: url('~@/assets/searchResults/icon_filter_with_active.svg');
        }
      }

      &__remove {
        margin-left: 10px;
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url('~@/assets/searchResults/icon_filter_without.svg');
        cursor: pointer;

        &:hover {
          background-image: url('~@/assets/searchResults/icon_filter_without_hover.svg');
        }

        &--active {
          background-image: url('~@/assets/searchResults/icon_filter_with_active.svg');
        }
      }
    }
  }
}

.more {
  margin-top: 5px;
  @include flex(center);
  &__icon {
    // @include position(br, 0, 0);

    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    background-image: url('~@/assets/searchResults/icon_info_open.svg');
    transition: 0.3s;
    transform: rotate(0deg);

    &--active {
      transform: rotate(180deg);
    }
  }
}
</style>

handleLabels
<template>
  <div class="v-search">
    <div
      class="v-search__content"
      :class="{
        'v-search__content--sticky': isStickySearch,
      }"
    >
      <div class="detail-filter">
        <div
          class="detail-filter__wrap"
          :class="{
            'detail-filter__wrap--active': isfilterExpand,
          }"
        >
          <filter-conditions v-model:isfilterExpand="isfilterExpand" />
        </div>
        <div class="detail-filter__main" ref="mainEle">
          <div
            class="function-bar-wrap"
            :class="{
              'function-bar-wrap--tracks': activeTab === 'tracks',
            }"
            :style="{
              top: `-${inputAndLabelsHeight ? inputAndLabelsHeight : 0}px`,
            }"
          >
            <div class="function-bar">
              <div class="g-content" ref="inputAndLabels">
                <div class="function-bar__content__left__input">
                  <search-input />
                </div>
                <div class="function-bar__content__left__search-labels">
                  <search-labels :labels="labels" @removeLabel="removeLabel" />
                </div>
              </div>

              <div class="function-bar__content g-content">
                <div class="function-bar__content__left">
                  <div class="function-bar__content__left__search-tabs">
                    <div class="sticky-status__input">
                      <search-input />
                    </div>
                    <div class="sticky-status__tabs">
                      <search-tabs :tabs="searchTabs" v-model:activeTab="activeTab" @handleTab="handleTab" />
                    </div>
                  </div>
                </div>
                <div
                  class="function-bar__content__right"
                  :class="{
                    'function-bar__content__right--tracks': activeTab === 'tracks',
                  }"
                >
                  <!-- <div class="function-bar__content__right__list-filter">

                  </div> -->
                  <div class="function-bar__content__right__other-filter">
                    <!-- <div class="function-bar__content__right__other-filter__select">
                      <mi-select
                        v-model:value="sortValue"
                        :options="mockOptions"
                        label="Sort by"
                        flexDirection="column"
                      />
                    </div> -->
                    <list-filter :data="functionConfig.listFilter" />
                    <add-playlists-btn :data="functionConfig.addPlaylistsBtn" />
                    <download-btn :data="functionConfig.downloadBtn" />
                  </div>
                </div>
              </div>
            </div>

            <div class="list-header">
              <div class="list-header__wrap g-content">
                <list-header :columns="listConfig.columns" />
              </div>
            </div>
          </div>
          <div class="search-result-wrap">
            <div class="search-result">
              <!-- <div class="search-result__content"> -->
                <div :class="[addGContent && 'g-content']">
                  <my-music-list :config="listConfig" :contentData="contentData" :showHeader="false" />
                </div>

                <div class="pagination-wrap g-content">
                  <mi-search-pagination :pager="pager" @handlePagination="handlePagination" />
                </div>
              <!-- </div> -->
            </div>
          </div>

          <search-footer />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent, ref, computed, provide, onMounted,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import FilterConditions from '@/components//SearchResult/FilterConditions.vue';
import AddPlaylistsBtn from '@/components/Global/AddPlaylistsBtn.vue';
import DownloadBtn from '@/components/Global/DownloadBtn.vue';
import SearchInput from '@/components/SearchResult/SearchInput.vue';
import SearchLabels from '@/components/SearchResult/SearchLabels.vue';
import SearchTabs from '@/components/SearchResult/SearchTabs.vue';
import ListFilter from '@/components/MyMusic/ListFilter.vue';
import MyMusicList from '@/components/MyMusic/MyMusicList/index.vue';
import ListHeader from '@/components/MyMusic/MyMusicList/ListHeader.vue';
// import MiSelect from '@/components/Global/Select/index.vue';
import MiSearchPagination from '@/components/Global/MiSearchPagination.vue';
import SearchFooter from '@/views/Search/SearchFooter.vue';
import {
  functionConfig as tracksFunctionConfig,
  contentData as tracksContentData,
  mobileListConfig as tracksMobileListConfig,
  pcListConfig as tracksPcListConfig,
  trackDivideColumns as tracksTrackDivideColumns,
  trackDivideData as tracksTrackDivideData,
  // mobileMusicDetailColumns as tracksMobileListDetailColumns,
  // pcMusicDetailColumns as tracksPcListDetailColumns,
  musicDetailColumns as tracksListDetailColumns,
  musicDetailContentData as tracksMusicDetailContentData,
} from '@/config/search/searchFilterTracksConfig';
import {
  functionConfig as albumsFunctionConfig,
  contentData as albumsContentData,
  mobileListConfig as albumsMobileListConfig,
  pcListConfig as albumsPcListConfig,
  musicDetailColumns as albumsMusicDetailColumns,
  musicDetailContentData as albumsMusicDetailContentData,
  trackDivideColumns as albumsTrackDivideColumns,
  trackDivideData as albumsTrackDivideData,
  mobileListDetailColumns as albumsMobileListDetailColumns,
  pcListDetailColumns as albumsPcListDetailColumns,
  listDetailContentData as albumsListDetailContentData,
} from '@/config/search/searchFilterAlbumsConfig';
import {
  functionConfig as playlistsFunctionConfig,
  contentData as playlistsContentData,
  mobileListConfig as playlistsMobileListConfig,
  pcListConfig as playlistsPcListConfig,
  musicDetailColumns as playlistsMusicDetailColumns,
  musicDetailContentData as playlistsMusicDetailContentData,
  trackDivideColumns as playlistsTrackDivideColumns,
  trackDivideData as playlistsTrackDivideData,
  mobileListDetailColumns as playlistsMobileListDetailColumns,
  pcListDetailColumns as playlistsPcListDetailColumns,
  listDetailContentData as playlistsListDetailContentData,
} from '@/config/search/searchFilterPlaylistsConfig';
import useAddGContent from '@/hooks/useAddGContent';
import { useSiteStore } from '@/store/site';
// import { useSearchStore } from '@/store/search';

export default defineComponent({
  name: 'Search',
  components: {
    SearchInput,
    FilterConditions,
    ListFilter,
    AddPlaylistsBtn,
    DownloadBtn,
    SearchLabels,
    SearchTabs,
    MyMusicList,
    // MiSelect,
    ListHeader,
    MiSearchPagination,
    SearchFooter,
  },
  setup() {
    const siteStore = useSiteStore();
    // const searchStore = useSearchStore();
    const addGContent = useAddGContent('sm-pc');
    const router = useRouter();
    const route = useRoute();
    const searchType = ref([]);
    const activeTab = ref(route.query.tab || 'tracks');
    const sortValue = ref('newest');
    const isStickySearch = ref(false);
    // const oldOffsetTop = ref(0);
    const isfilterExpand = ref(true);
    const mainEle = ref(null);
    const inputAndLabels = ref(null);
    const inputAndLabelsHeight = ref(0);
    const pager = ref({
      pageIndex: route.query?.pageIndex || 1,
      totalPage: 6,
    });

    // searchStore.updateAllLabels();

    const labels = ref([
      {
        label: '樂觀 Optimisitic',
        key: 'optimisitic',
        type: 'include',
      },
      {
        label: '無憂無慮 Carefree',
        key: 'carefree',
        type: 'include',
      },
      {
        label: '調皮 Playful',
        key: 'playful',
        type: 'exclude',
      },
    ]);
    const searchTabs = ref([
      {
        label: '曲目',
        key: 'tracks',
        total: 1,
      },
      {
        label: '專輯',
        key: 'albums',
        total: 10,
      },
      {
        label: '歌單',
        key: 'playlists',
        total: 5,
      },
    ]);

    const mockOptions = [
      {
        label: 'Name',
        value: 'name',
      },
      {
        label: 'Newest',
        value: 'newest',
      },
      {
        label: 'Oldest',
        value: 'oldest',
      },
    ];

    const removeLabel = (key) => {
      labels.value = labels.value.filter((item) => item.key !== key);
    };

    const handleTab = (key) => {
      activeTab.value = key;
      router.push({
        name: 'search',
        query: {
          tab: key,
        },
      });
    };

    const functionConfig = computed(() => {
      switch (route.query.tab) {
        case 'playlists':
          return playlistsFunctionConfig;
        case 'albums':
          return albumsFunctionConfig;
        default:
          return tracksFunctionConfig;
      }
    });

    const listConfig = computed(() => {
      if (siteStore.deviceType.includes('sm-pc')) {
        // const exactDeviceType = siteStore.deviceType.includes('pc') ? 'pc' : 'sm-pc';

        switch (route.query.tab) {
          case 'playlists':
            return playlistsPcListConfig;
          // ('sm-pc') || [];
          case 'albums':
            return albumsPcListConfig;
          // ('sm-pc') || [];
          default:
            return tracksPcListConfig;
        }
      }

      switch (route.query.tab) {
        case 'playlists':
          return playlistsMobileListConfig;
        case 'albums':
          return albumsMobileListConfig;
        default:
          return tracksMobileListConfig;
      }
    });

    const contentData = computed(() => {
      switch (route.query.tab) {
        case 'playlists':
          return playlistsContentData;
        case 'albums':
          return albumsContentData;
        default:
          return tracksContentData;
      }
    });

    const detailConfig = computed(() => {
      if (siteStore.deviceType.includes('sm-pc')) {
        switch (route.query.tab) {
          case 'playlists':
            return {
              listDetailColumns: playlistsPcListDetailColumns,
              listDetailContentData: playlistsListDetailContentData,
            };
          case 'albums':
            return {
              listDetailColumns: albumsPcListDetailColumns,
              listDetailContentData: albumsListDetailContentData,
            };
          default:
            return {
              listDetailColumns: tracksListDetailColumns,
              listDetailContentData: tracksMusicDetailContentData,
            };
        }
      }
      switch (route.query.tab) {
        case 'playlists':
          return {
            listDetailColumns: playlistsMobileListDetailColumns,
            listDetailContentData: playlistsListDetailContentData,
          };
        case 'albums':
          return {
            listDetailColumns: albumsMobileListDetailColumns,
            listDetailContentData: albumsListDetailContentData,
          };
        default:
          return {
            listDetailColumns: tracksListDetailColumns,
            listDetailContentData: tracksMusicDetailContentData,
          };
      }
    });

    const musicDetail = computed(() => {
      switch (route.query.tab) {
        case 'playlists':
          return {
            versionAndEdit: {
              columns: playlistsMusicDetailColumns,
              data: playlistsMusicDetailContentData,
            },
            track: {
              columns: playlistsTrackDivideColumns,
              data: playlistsTrackDivideData,
            },
            // mobile 點擊 row 展開的 key
            mobileExpandKey: 'detail',
          };
        case 'albums':
          return {
            versionAndEdit: {
              columns: albumsMusicDetailColumns,
              data: albumsMusicDetailContentData,
            },
            track: {
              columns: albumsTrackDivideColumns,
              data: albumsTrackDivideData,
            },
            // mobile 點擊 row 展開的 key
            mobileExpandKey: 'detail',
          };
        default:
          return {
            versionAndEdit: {
              columns: tracksListDetailColumns,
              data: tracksMusicDetailContentData,
            },
            track: {
              columns: tracksTrackDivideColumns,
              data: tracksTrackDivideData,
            },
            mobileExpandKey: 'mobile-music-player',
          };
      }
    });

    const listContentConfig = computed(() => {
      switch (route.query.tab) {
        case 'playlists':
          return {
            isSearch: true,
            // rowHeight: 'auto',
            notice: '',
            expandDetail: 'list-detail', // list-detail, music-detail, ''
          };
        case 'albums':
          return {
            isSearch: true,
            // rowHeight: 'auto',
            notice: '',
            expandDetail: 'list-detail', // list-detail, music-detail, ''
          };
        default:
          return {
            showExtraInfo: true,
            isSearch: true,
            // rowHeight: 'auto',
            notice: '',
            expandDetail: 'music-detail', // list-detail, music-detail, ''
          };
      }
    });

    provide('listContentConfig', listContentConfig);
    provide('detailConfig', () => detailConfig.value);
    provide('musicDetail', musicDetail);

    const handleStickyFilter = () => {
      inputAndLabelsHeight.value = inputAndLabels.value.clientHeight + 60;
      const yOffset = mainEle.value.scrollTop;
      console.log('yOffset', yOffset);
      console.log(inputAndLabelsHeight.value);
      if (siteStore.deviceType.includes('sm-pc')) {
        if (yOffset > inputAndLabelsHeight.value) {
          isStickySearch.value = true;
        } else {
          isStickySearch.value = false;
        }
      } else {
        isStickySearch.value = false;
      }
    };

    const handlePagination = (targetPage) => {
      pager.value.pageIndex = targetPage;

      router.push({
        name: 'search',
        query: {
          tab: route.query.tab,
          pageIndex: pager.value.pageIndex,
        },
      });
    };

    onMounted(() => {
      mainEle.value.addEventListener('scroll', handleStickyFilter);
    });

    return {
      searchType,
      functionConfig,
      searchTabs,
      activeTab,
      labels,
      removeLabel,
      listConfig,
      contentData,
      handleTab,
      mockOptions,
      sortValue,
      isStickySearch,
      isfilterExpand,
      mainEle,
      addGContent,
      pager,
      handlePagination,
      inputAndLabels,
      inputAndLabelsHeight,
      // allLabels: computed(() => searchStore.allLabels()),
    };
  },
});
</script>
<style lang="scss" scoped>
.v-search {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('~@/assets/explore/playlists_img.jpg');
  width: 100%;
  @include h100();
  overflow-y: auto;
  position: relative;

  &::before {
    content: '';
    background-color: rgba($c-black, 0.2);
    @include position(tl, 0, 0);
    width: 100%;
    height: 100%;
    display: block;
  }

  &__content {
    backdrop-filter: blur(30px);
    width: 100%;
    height: 100%;
  }
}
.detail-filter-wrap {
  display: none;
}

.function-bar-wrap {
}
.function-bar {
  width: 100%;
  @include padding(80px 0px 13px);
  background-color: $c-white;

  &__content {
    @include flex(space-between, flex-end);
    width: 100%;

    &__left {
      &__search-labels {
        display: none;
      }

      &__search-tabs {
        margin-top: 16px;
      }

      .sticky-status {
        &__input {
          display: none;
          // opacity: 0;
          // animation: fadeOut 0.3s forwards;
        }

        &__tabs {
          display: block;
          // animation: fadeOut 0.4s forwards;
        }
      }
    }

    &__right {
      display: none;
    }
  }
}

.list-header {
  box-shadow: 0px 3px 10px -3px #0000000d;
}

.search-result {
  width: 100%;
}

.pagination-wrap {
  width: 100%;
  @include flex(center);
  padding-bottom: 110px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  99% {
    opacity: 1;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    display: none;
  }
}

@media screen and (min-width: $sm-pc) {
  .v-search {
    &__content {
      background-color: $c-white;

      &--sticky {
        .function-bar__content__left {
          @include flex(flex-start, flex-start, column);
          transform: translateY(0px);
          position: relative;
          top: 0;

          .sticky-status {
            &__input {
              display: block;
              // animation: fadeIn 0.3s forwards;
            }

            &__tabs {
              display: none;
              // animation: fadeOut 0.4s forwards;
            }
          }

          // &__input {
          //   // transform: translateY(120px);
          //   margin-top: 80px;
          //   top: 10%;
          // }

          // &__search-labels {
          //   transform: translateY(-200px);
          // }

          // &__search-tabs {
          //   transform: translateY(-200px);
          // }
        }
      }
    }
  }

  .detail-filter {
    @include flex(flex-start, flex-start);
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;

    &__wrap {
      width: 0;

      transition: 0.3s;
      transform: translateX(0%);
      position: relative;
      z-index: 10;
      height: 100%;

      &--active {
        width: 280px;
      }
    }

    &__main {
      flex: 1;
      position: relative;
      z-index: 9;
      @include flex(flex-start, flex-start, column);
      // height: 100%;
      // height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      // height: calc(var(--vh, 1vh) * 100);
      @include h100();
      overflow-y: auto;
    }
  }

  .function-bar-wrap {
    width: 100%;
    position: sticky;
    // top: -220px;
    z-index: 10;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: auto 315px;
    background-color: $c-white;
    background-image: url('~@/assets/searchResults/img_deco.png');
  }
  .function-bar {
    // margin: 0 auto;
    box-sizing: border-box;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 100px;
    background-color: transparent;

    &__content {
      // padding-top: 10px;
      // padding-bottom: 20px;
      // @include padding(60px 60px 20px);

      &__left {
        &__input {
          transition: 0.3s;
        }

        &__search-labels {
          display: block;
          transition: 0.3s;
        }

        &__search-tabs {
          // margin-top: 40px;
          transition: 0.3s;
        }

        .sticky-status {
          &__input {
            display: none;
            // opacity: 0;
            // animation: fadeOut 0.3s forwards;
          }

          &__tabs {
            display: block;
            // animation: fadeOut 0.4s forwards;
          }
        }
      }

      &__right {
        display: block;
        margin-left: 20px;

        &__list-filter {
          margin-bottom: 12px;
          @include flex(flex-end);
        }

        &__other-filter {
          @include flex(flex-end);
          position: relative;

          &__select {
            margin-right: 12px;
          }
        }
      }
    }
  }

  .list-header {
    @include padding(0 60px);
  }

  .search-result-wrap {
    width: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    background-image: url('~@/assets/explore/playlists_img.jpg');

  }

  .search-result {
    @include padding(0 60px);

      backdrop-filter: blur(30px);
    &__content {
    }
  }

  .pagination-wrap {
    @include flex(flex-end);
    margin-bottom: 0px;
  }
}

@media screen and (min-width: $pc) {
  .v-search {
    &__content {
      background-color: $c-white;

      &--sticky {
        .function-bar__content__left {
          &__input {
            margin-top: 0px;
          }
        }
      }
    }
  }

  .function-bar-wrap {
    top: -100px;

    &--tracks {
      top: -100px;
    }
  }

  .function-bar {
    // padding-left: 60px;
    // padding-right: 60px;

    &__content {
      padding-top: 0;

      &__right {
        @include flex();
        margin-left: 20px;

        &--tracks {
          display: block;

          .function-bar__content__right__list-filter {
            margin-bottom: 12px;
          }

          .function-bar__content__right__other-filter {
            &__select {
              &::before {
                display: none;
              }
            }
          }
        }

        &__list-filter {
          margin-bottom: 0px;
          @include flex(flex-end);
        }

        &__other-filter {
          @include flex();
          position: relative;

          &__select {
            margin-right: 12px;
            position: relative;

            &::before {
              content: '';
              background-color: $c-divide-red;
              width: 1px;
              height: 20px;
              @include position(tl, 50%, -10px);
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $lg-pc) {
  .function-bar {
    &__content {
      padding-top: 0;
    }
  }
}
</style>

<template>
  <div class="c-search-footer">
    <div class="c-search-footer__content g-content">
      <div class="c-search-footer__content__left">
        <ul class="explore-list">
          <li
            class="explore-list__item"
            v-for="item of bannerTabsConfig"
            :key="item.key"
            @click="toExplorePage(item.key)"
          >
            {{ item.label }}
          </li>
        </ul>
      </div>
      <div class="c-search-footer__content__right">
        <div class="about-terms">
          <p class="about-terms__about" @click="toPage('front-about-us')">About us</p>
          <p class="about-terms__terms" @click="toPage('licensing-terms')">Terms & Conditions</p>
        </div>
        <p class="copyright">Copyright © {{currYear}} Point Culture. All rights reserved.</p>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import bannerTabsConfig from '@/config/bannerTabsConfig';
import currYear from '@/utils/currYear';

export default defineComponent({
  name: 'SearchFooter',
  setup() {
    const router = useRouter();

    const toExplorePage = (type) => {
      router.push({
        name: 'explore-type',
        params: {
          type,
        },
      });
    };

    const toPage = (name) => {
      router.push({
        name,
      });
    };

    return {
      bannerTabsConfig,
      toPage,
      toExplorePage,
      currYear,
    };
  },
});
</script>
<style lang="scss" scoped>

.c-search-footer {
  display: none;
}

@media screen and (min-width: $sm-pc) {
.c-search-footer {
  display: block;
  width: 100%;
  background-color: $c-black;

  &__content {
    height: 80px;

    @include flex(space-between);

    &__left {
      // height: 100%;
      @include flex();
    }

    &__right {
      // height: 100%;
      @include flex();
    }
  }
}

.explore-list {
  @include flex();

  &__item {
    @include font-style($c-white, 14, 400, 0.7px, 26px);
    text-transform: uppercase;
    cursor: pointer;

    & + & {
      margin-left: 20px;
    }
  }
}

.about-terms {
  @include flex();

  &__about {
    @include font-style($c-white, 12, 400, 0.6px, 18px);
    cursor: pointer;
  }

  &__terms {
    @include font-style($c-white, 12, 400, 0.6px, 18px);
    margin-left: 29px;
    position: relative;
    cursor: pointer;

    &::before {
      content: '';
      width: 1px;
      height: 16px;
      @include position(tl, 50%, -15px);
      transform: translateY(-50%);
      display: inline-block;
      background-color: $c-white;
    }
  }
}

.copyright {
  @include font-style($c-assist4, 12, 400, 0.6px, 18px);
  margin-left: 20px;
}}
</style>

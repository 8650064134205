export default [
  {
    key: 'themes',
    label: '主題',
  },
  {
    key: 'moods',
    label: '氣氛',
  },
  {
    key: 'genres',
    label: '曲風',
  },
  {
    key: 'albums',
    label: '專輯',
  },
  {
    key: 'playlists',
    label: '歌單',
  },
  {
    key: 'labels',
    label: '品牌',
  },
];

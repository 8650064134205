vinit
<template>
  <div class="c-type-and-name" v-if="data.labelWith || data.labelWithout">
    <div class="filter filter--top" v-if="data.labelWith">
      <p class="filter__label">With</p>
      <div class="filter__label-item-wrap">
        <label-item v-for="l of data.labelWith" :label="l" :key="l" />
      </div>
    </div>
    <!-- 若沒有 labelWith 會跑到上面，需要另外+外距 -->
    <div class="filter" :class="{'filter--top': data.labelWith && !data.labelWithout}" v-if="data.labelWithout">
      <p class="filter__label">Without</p>
      <div class="filter__label-item-wrap">
        <label-item v-for="l of data.labelWithout" :label="l" :key="l" />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import LabelItem from '@/components/Global/LabelItem.vue';

export default defineComponent({
  name: 'TypeAndName',
  components: {
    LabelItem,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
});
</script>
<style lang="scss" scoped>
.filter {
  @include flex(flex-start, flex-start);

  &--top {
    margin-top: 10px;
  }

  &__label {
    width: 45px;
    margin-right: 30px;
    @include font-style($c-assist4, 12, 600, 0px, 26px);
  }

  &__label-item-wrap {
    @include flex();
  }
}
</style>

export const firstLayer = [
  {
    label: '版本',
    selectedCount: 0,
    key: 'version',
  },
  {
    label: '剪輯',
    selectedCount: 0,
    key: 'edit',
  },
  {
    label: '樂器',
    selectedCount: 0,
    key: 'instrument',
  },
  {
    label: '曲風',
    selectedCount: 0,
    key: 'genres',
  },
  {
    label: '氣氛',
    selectedCount: 3,
    key: 'atmospheres',
  },
  {
    label: '速度',
    selectedCount: 0,
    key: 'speed',
  },
  {
    label: '音樂用途',
    selectedCount: 0,
    key: 'usedWay',
  },
  {
    label: '時代',
    selectedCount: 0,
    key: 'era',
  },
  {
    label: '唱片公司',
    selectedCount: 0,
    key: 'albumCompany',
  },
];

export const secondLayer = [
  {
    label: '快樂/正面',
    labelEn: 'Happy/Positive',
    total: 40,
    key: 'happyPositive',
    id: 'happyPositive',
    detail: [
      {
        label: '調皮',
        labelEn: 'Playful',
        total: 10,
        key: 'playful',
      },
      {
        label: '樂觀',
        labelEn: 'Optimisitic',
        total: 10,
        key: 'optimisitic',
      },
    ],

  },
  {
    label: '幽默',
    labelEn: 'Humour',
    total: 40,
    key: 'humour',
    id: 'humour',
    detail: [
      {
        label: '調皮',
        labelEn: 'Playful',
        total: 10,
        key: 'playful',
      },

    ],

  },
  {
    label: '動感',
    labelEn: 'Motion',
    total: 40,
    key: 'motion',
    id: 'motion',
    detail: [
      {
        label: '調皮',
        labelEn: 'Playful',
        total: 10,
        key: 'playful',
      },

    ],
  },
  {
    label: '夢幻',
    labelEn: 'Fantasy',
    total: 40,
    key: 'fantasy',
    id: 'fantasy',
    detail: [
      {
        label: '調皮',
        labelEn: 'Playful',
        total: 10,
        key: 'playful',
      },
      {
        label: '樂觀',
        labelEn: 'Optimisitic',
        total: 10,
        key: 'optimisitic',
      },
    ],
  },
  {
    label: '浪漫',
    labelEn: 'Romantic',
    total: 40,
    key: 'romantic',
    id: 'romantic',
    detail: [
      {
        label: '調皮',
        labelEn: 'Playful',
        total: 10,
        key: 'playful',
      },

    ],
  },
  {
    label: '優美',
    labelEn: 'Beauty',
    total: 40,
    key: 'beauty',
    id: 'beauty',
    detail: [
      {
        label: '調皮',
        labelEn: 'Playful',
        total: 10,
        key: 'playful',
      },
      {
        label: '樂觀',
        labelEn: 'Optimisitic',
        total: 10,
        key: 'optimisitic',
      },
    ],
  },
  {
    label: '詭譎',
    labelEn: 'Odd',
    total: 40,
    key: 'odd',
    id: 'odd',
    detail: [
      {
        label: '調皮',
        labelEn: 'Playful',
        total: 10,
        key: 'playful',
      },
    ],
  },
  {
    label: 'Mood',
    labelEn: '',
    total: 40,
    key: 'mood',
    id: 'mood',
    detail: [
      {
        label: '調皮',
        labelEn: 'Playful',
        total: 10,
        key: 'playful',
      },

    ],
  },
];

export const thirdLayer = [
  {
    label: '調皮',
    labelEn: 'Playful',
    total: 10,
    key: 'playful',
  },
  {
    label: '樂觀',
    labelEn: 'Optimisitic',
    total: 10,
    key: 'optimisitic',
  },
  {
    label: '無憂無慮',
    labelEn: 'Carefree',
    total: 10,
    key: 'carefree',
  },
  {
    label: '響亮',
    labelEn: 'Bright',
    total: 10,
    key: 'bright',
  },

];

<template>
  <div class="c-order-and-name">
    <p class="text">
      <span class="text__order"> {{idx +1}}.</span>
      <span class="text__name">{{ data.name }}</span>
    </p>

  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OrderAndName',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    idx: {
      type: Number,
      default: 0,
    },
  },
});
</script>
<style lang="scss" scoped>
.text {
  @include padding(0px 20px 0px 0);
  max-width: 500px;

  &__order {
    @include font-style($c-assist3, 16, bold, 0.8px, 26px);
    margin-right: 16px;
  }

  &__name {
    margin-left: 5px;
    @include font-style($c-black, 16, bold, 0.8px, 26px);

  }
}

</style>
